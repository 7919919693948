<template>
  <div class="mainBody">
    <div class="realData">
      <div class="topSearch" ref="other">
        <div style="display: flex; flex: 1;">
          <div class="tabsBox">
            <div class="tabs" @click="handleClick(2)">
              <div class="tab" :class="{ 'actie': pageParams.installType == 2 }">管网</div>
            </div>
            <div class="tabs" @click="handleClick(1)">
              <div class="tab" :class="{ 'actie': pageParams.installType == 1 }">场站</div>
            </div>
          </div>
          <div class="search">
            <el-form :inline="true" :model="pageParams" class="demo-form-inline" label-position="right">
              <el-form-item label="">
                <el-input placeholder="请输入" size="small" class="selectBox input-with-select"
                  v-model="pageParams.queryWord" style="width:300px">
                  <el-select v-model="pageParams.queryType" slot="prepend" placeholder="请选择" style="width:100px">
                    <el-option label="设备名称" value="1"></el-option>
                    <!-- <el-option label="所在位置" value="2"></el-option> -->
                    <el-option label="监测点位" value="3"></el-option>
                    <!-- <el-option label="异常信息" value="4"></el-option> -->
                  </el-select>
                </el-input>
              </el-form-item>
              <!-- <el-form-item label="名称">
                <el-input v-model="pageParams.queryWord" clearable class="search-input" placeholder="报警器名称"></el-input>
              </el-form-item> -->
              <!-- <el-form-item label="状态">
                <el-select v-model="pageParams.generalStatus" class="search-input" clearable filterable placeholder="请选择">
                  <el-option label="启用" :value="1"></el-option>
                  <el-option label="禁用" :value="0"></el-option>
                </el-select>
              </el-form-item> -->
              <el-form-item>
                <div class="search-q">
                  <el-button type="primary" @click="search">查询</el-button>
                  <el-button type="info" plain @click="reset">重置</el-button>
                </div>
              </el-form-item>
            </el-form>
          </div>
        </div>
        <div>
          <div class="search">
            <el-form>
              <el-form-item>
                <div class="search-q">
                  <el-button  type="primary" plain  @click="exportHis()">导出</el-button>
                </div>
              </el-form-item>
            </el-form>
          </div>
        </div>
      </div>
      <div class="content" :style="{ height: height }">
        <el-table height="calc(100% - 65px)" border :data="list" style="width: 100%;">
          <el-table-column prop="equipmentName" label="设备名称"> </el-table-column>
          <el-table-column prop="description" label="所在位置"> </el-table-column>
          <el-table-column prop="monitorPointName" label="监测点位"> </el-table-column>
          <el-table-column prop="val" label="远传数据">
            <template slot-scope="{ row }">
              {{row.val + row.unit}}
            </template>
          </el-table-column>
          <el-table-column prop="dataType" label="数据类型"> </el-table-column>
          <el-table-column prop="warningDescribe" label="异常信息"> </el-table-column>
          <el-table-column prop="uploadTime" label="上报时间"> </el-table-column>
          <el-table-column prop="collectTime"  label="采集时间">
          </el-table-column>
        </el-table>
        <el-pagination background layout="total,sizes,prev, pager, next" :page-size="pageParams.size"
          :current-page="pageParams.current" :page-sizes="[30, 50, 100]" :total="total"
          @size-change="handleSizeChange" @current-change="currentChange">
        </el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import scadaApi from "@/apis/scadaApi";
import { exportMethod } from '@/common/js/exportExcel'
import { historyPage } from '@/apis/warn/alarmManagement/realData'
export default {
  name: 'historyData',
  components: {
  },
  data() {
    return {
      pageParams: {
        installType: 2,
        queryType:"1",
        equipmentType: 4,
        current: 1,
        size: 100,
      },
      list: [

      ],
      total: 0,
      height: 0,
    }
  },
  mounted() {
    this.loadList(this.pageParams)
    this.$nextTick(() => {
      this.height = `calc(100% - ${this.$refs.other.scrollHeight}px)`
    })
  },
  methods: {
    handleClick(e) {
    
      this.pageParams = {
        installType: 2,
        queryType:"1",
        equipmentType: 4,
        current: 1,
        size: 100,
     
      }
      if (this.pageParams.installType != e) {
        this.pageParams.installType = e
      }
      this.loadList(this.pageParams)
    },
    search() {
      this.pageParams.current = 1
      this.loadList(this.pageParams)
    },
    reset() {
      let installType = this.pageParams.installType
      this.pageParams = {
        installType: installType,
        queryType:"1",
        equipmentType: 4,
        current: 1,
        size: 100,
      }
      this.loadList(this.pageParams)
    },
    handleSizeChange(val) {
      this.pageParams.current = 1
      this.pageParams.size = val
      this.loadList(this.pageParams)
    },
    currentChange(current) {
      this.pageParams.current = current
      this.loadList(this.pageParams)
    },
    loadList(data) {
      let obj = JSON.parse(JSON.stringify(data))
      historyPage(obj).then(res => {
        if (res.code == 200) {
          this.list = res.data.list
          this.total = res.data.total
        }
      })
    },
    exportHis(){
           let obj = JSON.parse(JSON.stringify(this.pageParams))
          //  if(this.time){
          //       obj.startTime = this.time[0]
          //       obj.endTime = this.time[1]
          //   }
           obj.current = 0
           obj.size = -1
           scadaApi.exportScadaHistory(obj).then(res => {
                exportMethod(res,'历史数据')
           }) 
        }
  }
}
</script>

<style lang="scss" scoped>
::v-deep .el-form-item__content .el-input-group {
  vertical-align: middle;
}
::v-deep .el-input--small .el-input__icon{
  line-height: 25px;
}
::v-deep .search {
  .el-form-item__content {
    line-height: 43px;
  }

  .el-form-item {
    margin-bottom: 0;
    margin-right: 20px;
  }

  .el-form-item__label {
    color: #0F0F0F;
    padding-right: 5px;
    line-height: 43px;
  }

  .el-input__inner {
    height: 28px;
  }

  .el-input__suffix {
    top: -3px
  }

  .el-button {
    padding: 6px 10px;
  }
}

.mainBody {
  padding: 10px;
  height: calc(100vh - 175px);

  .tabsBox {
    display: flex;
    // justify-content: space-between;
    // flex: 0 0 150px;

    .tabs {
      cursor: pointer;
      padding: 0 20px;

      .tab {
        height: 100%;
        box-sizing: border-box;
        border-bottom: 2px solid transparent;
        display: flex;
        align-items: center;

        &.actie {
          color: #1082FF;
          border-bottom: 2px solid #1082FF;
        }
      }
    }

  }

  .realData {
    background: #fff;
    height: 100%;
    position: relative;

    .topSearch {
      border-bottom: 1px solid #E3E3E3;
      display: flex;
      justify-content: space-between;

      .tabsBox {
        display: flex;
        // justify-content: space-between;
        flex: 0 0 150px;

        .tabs {
          cursor: pointer;
          padding: 0 20px;

          .tab {
            height: 100%;
            box-sizing: border-box;
            border-bottom: 2px solid transparent;
            display: flex;
            align-items: center;

            &.actie {
              color: #1082FF;
              border-bottom: 2px solid #1082FF;
            }
          }
        }

      }

      .search {}
    }

    .content {
      width: 100%;
      // height: calc(100% - 45px);
      padding: 10px 10px 0 10px;
      box-sizing: border-box;
      background: #fff;
    }
  }
}
</style>
